import React, {useEffect} from 'react';

import { Button } from '../components/button';
import { Container } from '../components/container';

// import { Link } from 'gatsby';
const ThankYouDT = () => {
  useEffect(() => {
    const isEn = document.location.pathname.includes('/en');

    const userData = localStorage.getItem('order-food-contact-information');
    const data = JSON.parse(userData);

    const name = data?.name;
    const phone = data?.phone;
    const email = data?.email;
    const zip = data?.deliveryAddress?.components?.postcode ? data?.deliveryAddress?.components?.postcode : '';
    const city = data?.city?.label ? data?.city?.label : '';
    const state = 'CZ';

    let firstName = '';
    let lastName = '';

    if (name) {
      const nameParts = name.split(' ');
      firstName = nameParts[0];
      lastName = nameParts.slice(1).join(' ');
    }

    const userInfo = {
      firstName,
      lastName,
      phone,
      email,
      zip,
      city,
      state,
    };


    // setTimeout(() => {
    //   window.dataLayer &&
    //   window.dataLayer.push({
    //     event: 'purchase',
    //     pageURL: isEn ? '/en/thank-you' : '/thank-you',
    //     pageType: 'Purchase',
    //     userInfo,
    //   });
    // }, 300);
  }, []);

  return (
    <>
      <Container className="text-center">
        <h1 className="text-center" style={{ marginTop: '100px' }}>
          Vaše žádost byla
          <br />
          <span style={{ color: 'var(--color-green)' }}>úspěšně přijata</span>
        </h1>
        <Button type="primary">
          <a href="/">Zpět na hlavní stránku</a>
        </Button>
      </Container>
    </>
  );
};

export default ThankYouDT;